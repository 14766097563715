<template>
	<div class="home">
		<div class="content"> 
			<div class="div bg_img">
				<img :src="isMobile ? require('../assets/img/mobile_icon1.png') : require('../assets/img/down_1.png')" alt="">
				<div class="bg_text">
					<img :src="require('../assets/img/logo-bg.png')" alt="" class="bg-img">
					<p class="text2">垃圾分类要分好</p>
					<p class="text2">钱海屯回收必不可少</p>

					<div v-if="!isMobile" class="bg_code">
						<div class="code_box" v-for="(item, index) in List" :key="index" @click="choose(index)" @mouseover="choose(index)">
							<div class="code_btn">
								<img :src="item.icon" alt />
								<p>{{ item.name }}</p>
							</div>

							<div v-show="chooseName && curIndex == index" class="code_mess">
								<div class="code_text">
									<img :src="curIndex ? require('../assets/img/iOS_hover.png') : require('../assets/img/Android_hover.png')" alt />
									<p>{{ chooseName }}</p>
								</div>
								<img :src="index == 1 ? require('../assets/img/ios_qrcode.png') : require('../assets/img/android_QRcode.png')" alt />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="div bg_imgAll" v-for="(itm, idx) in nextList" :key="idx">
				<img :src="itm.icon_url" alt="">
				<div class="img_text">
					<p class="text3">{{ itm.text1 }}</p>
					<p class="text4">{{ itm.text2 }}</p>
				</div>
			</div>

			<!-- <img v-if="!isMobile" src="../assets/img/down_6.png" alt /> -->

			<div class="div content_bottom">
				<img v-if="!isMobile" src="../assets/img/down_7.png" alt />
				<div class="bottom_box">
					<p v-if="!isMobile" class="title">扫码下载客户端</p>

					<div v-if="!isMobile" class="bg_code">
						
						<div class="code_box" v-for="(item, index) in List" :key="index" @click="choose(index)" @mouseover="choose(index)">
							<div class="code_btn">
								<img :src="item.icon" alt />
								<p>{{ item.name }}</p>
							</div>

							<div v-show="chooseName && curIndex == index" class="code_mess">
								<div class="code_text">
									<img :src="curIndex ? require('../assets/img/iOS_hover.png') : require('../assets/img/Android_hover.png')" alt />
									<p>{{ chooseName }}</p>
								</div>
								<img :src="index == 1 ? require('../assets/img/ios_qrcode.png') : require('../assets/img/android_QRcode.png')" alt />
							</div>
						</div>
					</div>

					<div class="conpy">
						<p>Copyright &copy;{{ year }} 版权所有：深圳市钱海屯物联网科技有限公司</p>
						<p><a style="color: #191C27;" target="_blank" href="https://beian.miit.gov.cn/">备案号:粤ICP备2021038166号</a></p>
					</div>
				</div>
			</div>
		</div>

		<div v-if="isMobile" class="downBtn_box"><button class="downBtn" @click="downApp">下载钱海屯回收</button></div>
	</div>
</template>

<script>
export default {
	name: 'AppDownload',
	data() {
		return {
			chooseName: null,
			curIndex: 0,
			isMobile: true,
			year: '',
			List: [{ name: 'Android 版', icon: require('../assets/img/Android_nor.png') }, { name: 'iOS 版', icon: require('../assets/img/iOS_nor.png') }],
			nextList: [
				{ text1: '投递', text2: '手机一键开柜，轻松投递', icon_url: '', height: 1200 },
				{ text1: '回收', text2: '在线预约，上门回收', icon_url: '', height: 1200 },
				{ text1: '服务', text2: '家政二手资讯，应有尽有', icon_url: '', height: 1200 },
				{ text1: '商城', text2: '环保金积分兑换', icon_url: '', height: 1200 },
				{ text1: '环保金', text2: '投垃圾、预约回收产生环保金', icon_url: '', height: 1396 }
			]
		};
	},
	mounted() {
		this.Mobile();
		if (this.isMobile) {
			console.log('手机端');
			this.nextList[0].icon_url = require('../assets/img/mobile_icon2.png');
			this.nextList[1].icon_url = require('../assets/img/mobile_icon3.png');
			this.nextList[2].icon_url = require('../assets/img/mobile_icon4.png');
			this.nextList[3].icon_url = require('../assets/img/mobile_icon5.png');
			this.nextList[4].icon_url = require('../assets/img/mobile_icon6.png');
			//打开（下载）App
			var ua = window.navigator.userAgent.toLowerCase();
			//微信
			if (ua.match(/MicroMessenger/i) == 'micromessenger') {
				//提示在外部浏览器打开
				this.$emit('showMask', true);
			}
			//非微信浏览器
			else {
				if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
					var loadDateTime = new Date();
					window.setTimeout(function() {
						var timeOutDateTime = new Date();
						if (timeOutDateTime - loadDateTime < 5000) {
							window.location = '//itunes.apple.com/us/app/%E9%92%B1%E6%B5%B7%E5%B1%AF/id1626425215?l=zh&ls=1&mt=8'; //ios下载地址
						} else {
							window.close();
						}
					}, 2000);
					//window.location = 'weibo://'; //ios提供打开协议
				} else if (navigator.userAgent.match(/android/i)) {
					try {
						//window.location = 'weibo://'; //android提供打开协议
						setTimeout(function() {
							window.location = 'https://static.qianhaitun.com/qhtapp/qianhaitun.apk'; //android下载地址
						}, 500);
					} catch (e) {}
				}
			}
		} else {
			console.log('pc端');
			this.nextList[0].icon_url = require('../assets/img/down_2.png');
			this.nextList[1].icon_url = require('../assets/img/down_3.png');
			this.nextList[2].icon_url = require('../assets/img/down_4.png');
			this.nextList[3].icon_url = require('../assets/img/down_5.png');
			this.nextList[4].icon_url = require('../assets/img/down_6.png');
		}
	},
	created() {
		this.year = new Date().getFullYear()
	},
	methods: {
		choose(index) {
			this.curIndex = index;
			this.curIndex == 0 ? (this.chooseName = 'Android 版') : (this.chooseName = 'iOS 版');
		},
		Mobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			flag ? (this.isMobile = true) : (this.isMobile = false);
		},
		downApp() {
			//打开（下载）App
			var ua = window.navigator.userAgent.toLowerCase();
			//微信
			if (ua.match(/MicroMessenger/i) == 'micromessenger') {
				//提示在外部浏览器打开
				this.$emit('showMask', true);
			}
			//非微信浏览器
			else {
				if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
					var loadDateTime = new Date();
					window.setTimeout(function() {
						var timeOutDateTime = new Date();
						if (timeOutDateTime - loadDateTime < 5000) {
							window.location = '//itunes.apple.com/us/app/%E9%92%B1%E6%B5%B7%E5%B1%AF%E5%9B%9E%E6%94%B6/id1626425215?l=zh&ls=1&mt=8'; //ios下载地址
						} else {
							window.close();
						}
					}, 2000);
					//window.location = 'weibo://'; //ios提供打开协议
				} else if (navigator.userAgent.match(/android/i)) {
					try {
						//window.location = 'weibo://'; //android提供打开协议
						setTimeout(function() {
							window.location = 'https://static.qianhaitun.com/qhtapp/qianhaitun.apk'; //android下载地址
						}, 500);
					} catch (e) {}
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.home {
	overflow: hidden;
}
.content {
	position: relative;
	margin: 0 auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	.div {
		position: relative;
		float: left;
		width: 100%;
		background-position: top center;
		background-size: cover;
	}
	.div::after {
		clear: both;
		display: table;
		content: '';
	}
	.bg_img {
		// background: url('../assets/img/down_1.png') no-repeat;
		// height: 1080px;
		display: flex;
		justify-content: center;
		position: relative;
		width: 100%;
		height: 100%;
		img {
			height: 100%;
		}
		.bg_text {
			position: absolute;
			display: flex;
			flex-direction: column;
			color: #191c27;
			padding-left: 30%;
			margin-top: 16%;
			.bg-img {
				margin-bottom: 32px;
				height: 64px;
				width: 420px;
			}
			.text1 {
				font-size: 40px;
				font-weight: 400;
			}
			.text2 {
				font-size: 60px;
				font-weight: 100;
			}
		}
	}
	.bg_code {
		display: flex;
		position: relative;
		.code_btn {
			padding: 11px 0;
			width: 155px;
			background: #ffffff;
			border-radius: 20px;
			display: flex;
			justify-content: center;
			align-content: center;
			margin-right: 16px;
			margin-top: 40px;
			font-size: 18px;
			img {
				display: inline-block;
				width: 26px;
				height: 26px;
				margin-right: 8px;
			}
		}
		.code_mess {
			position: absolute;
			top: 0;
			cursor: pointer;
			margin-top: 40px;
			width: 150px;
			height: 173px;
			background: #ffffff;
			box-shadow: 5px 5px 10px 0px rgba(203, 211, 218, 0.5);
			border-radius: 21px;
			border: 1px solid #157efb;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-size: 18px;
			.code_text {
				display: flex;
				img {
					display: inline-block;
					width: 26px;
					height: 26px;
					margin-right: 8px;
				}
				p {
					color: #157efb;
					padding-bottom: 11px;
				}
			}
			img {
				width: 120px;
				height: 120px;
			}
		}
	}
	.img_text {
		z-index: 1;
		position: absolute;
		top: 50px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.bg_imgAll {
		// width: 1920px;
		// height: 980px;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.text3 {
		font-size: 40px;
		color: #157efb;
		font-weight: 400;
		padding-bottom: 32px;
	}
	.text4 {
		font-size: 24px;
		color: #191c27;
		font-weight: 100;
	}
	// img {
	// 	width: 1920px;
	// 	display: block;
	// }
	.content_bottom {
		// background: url('../assets/img/down_7.png') no-repeat;
		// height: 474px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		img {
			width: 100%;
		}
		.bottom_box {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			position: absolute;
			z-index: 1;
			.title {
				font-size: 16px;
				color: #191c27;
				font-weight: 100;
			}
			.conpy {
				padding-top: 246px;
				padding-bottom: 60px;
				width: 100%;
				text-align: center;
				color: #191c27;
				font-size: 12px;
				> p:nth-child(1) {
					padding-bottom: 10px;
				}
			}
		}
	}
}

@media screen and (max-width: 991px) {
	.content {
		position: relative;
		margin: 0 auto;
		width: 100%;
		.div {
			position: relative;
			width: 100%;
		}
		.div::after {
			clear: both;
			display: table;
			content: '';
		}
		.bg_img {
			background-image: none;
			// background-image: url('../assets/img/mobile_icon1.png');
			// background-position: bottom;
			// background-size: cover;
			height: 100%;
			display: flex;
			justify-content: unset;
			position: relative;
			img {
				width: 100%;
			}
			.bg_text {
				position: absolute;
				display: flex;
				flex-direction: column;
				color: #191c27;
				padding-left: 9%;
				top: 60px;
				.text1 {
					font-size: 40px;
					font-weight: 400;
				}
				.text2 {
					font-size: 30px;
					font-weight: 100;
				}
				.text2:nth-child(2) {
					padding-top: 10px;
				}
			}
		}
		.bg_code {
			display: flex;
			position: relative;
			.code_btn {
				padding: 11px 0;
				width: 155px;
				background: #ffffff;
				border-radius: 20px;
				display: flex;
				justify-content: center;
				align-content: center;
				margin-right: 16px;
				margin-top: 40px;
				font-size: 18px;
				img {
					display: inline-block;
					width: 26px;
					height: 26px;
					margin-right: 8px;
				}
			}
			.code_mess {
				position: absolute;
				top: 0;
				cursor: pointer;
				margin-top: 40px;
				width: 150px;
				height: 173px;
				background: #ffffff;
				box-shadow: 5px 5px 10px 0px rgba(203, 211, 218, 0.5);
				border-radius: 21px;
				border: 1px solid #157efb;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				font-size: 18px;
				.code_text {
					display: flex;
					img {
						display: inline-block;
						width: 26px;
						height: 26px;
						margin-right: 8px;
					}
					p {
						color: #157efb;
						padding-bottom: 11px;
					}
				}
				img {
					width: 120px;
					height: 120px;
				}
			}
		}
		.img_text {
			z-index: 1;
			position: absolute;
			left: 10%;
			top: 130px;
			display: flex;
			flex-direction: column;
			justify-content: unset;
			align-items: unset;
		}
		.bg_imgAll {
			// background-image: url('../assets/img/mobile_icon1.png');
			// background-position: bottom;
			// background-size: cover;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: -2px;
			img {
				width: 100%;
			}
		}
		.text3 {
			font-size: 40px;
			color: #157efb;
			font-weight: 400;
			padding-bottom: 32px;
		}
		.text4 {
			font-size: 24px;
			color: #191c27;
			font-weight: 100;
		}
		.content_bottom {
			// background-image: url('../assets/img/mobile_icon6.png');
			// height: 1444px;
			background: none;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.bottom_box {
				display: flex;
				flex-direction: column;
				align-items: center;
				position: absolute;
				.title {
					font-size: 16px;
					color: #191c27;
					font-weight: 100;
				}
				.conpy {
					padding-top: 0;
					width: 100%;
					text-align: center;
					color: #191c27;
					font-size: 20px;
					padding-bottom: 120px;
					> p:nth-child(1) {
						padding-bottom: 10px;
					}
				}
			}
		}
	}
	.downBtn_box {
		position: fixed;
		bottom: 60px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-content: center;
		z-index: 999;
		.downBtn {
			flex: 1;
			padding: 24px 0;
			margin: 0 64px;
			background: linear-gradient(135deg, #2fb5fd 0%, #157efb 100%);
			box-shadow: 6px 6px 16px 0px rgba(21, 126, 251, 0.5);
			border-radius: 48px;
			border: none;
			text-align: center;
			font-size: 32px;
			color: #fff;
		}
	}
}
</style>
